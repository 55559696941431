import React from 'react';
import PropTypes from 'prop-types';

import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import Business from '../components/home/homeBusiness';
import Companies from '../components/home/homeCompanies';
import CTA from '../components/ctaContainer';
import Engage from '../components/home/homeEngage';
import Good from '../components/home/homeGood';
import Hero from '../components/home/homeHero';
import Layout from '../components/layout';
import Quote from '../components/home/homeQuote';
import Solutions from '../components/home/homeSolutions';
import VideoEmbed from '../components/home/homeWistia';

/**
 * Homepage component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Home location={string} />
 */

const Home = ({ location }) => (
  <Layout location={location}>
    <Hero />
    <Engage />
    <VideoEmbed />
    <Quote />
    <Good />
    <Companies />
    <Business />
    <Solutions />
    <CTA
      background={main.colorGradientBlue}
      button={theme.dpBtnBlue}
      color={theme.colorBlue}
      solution="profile"
    />
  </Layout>
);

Home.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Home;
