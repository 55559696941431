import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import CleanHTML from '../cleanHTML';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * Homepage Business component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Business />
 */

const Business = () => {
  const {
    contentfulHomepage: {
      businessHeading,
      businessCopy,
      businessFooter,
      businessImages,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        businessHeading
        businessCopy {
          childMarkdownRemark {
            html
          }
        }
        businessFooter
        businessImages {
          file {
            fileName
            url
          }
        }
      }
      commercial: contentfulAsset(title: { eq: "commercial" }) {
        file {
          url
        }
      }
      medicaid: contentfulAsset(title: { eq: "medicaid" }) {
        file {
          url
        }
      }
      medicare: contentfulAsset(title: { eq: "medicare" }) {
        file {
          url
        }
      }
    }
  `);

  const images = businessImages.map(image => ({
    url: image.file.url,
    label: image.file.fileName.replace(/\.[a-z]{3,4}$/g, ''),
  }));

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.homeBusiness,
        theme.shapeBgGrey
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.homeBusinessInner
        )}
      >
        <ul
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsCenter,
            base.justifyBetween,
            base.ma2,
            base.pa0,
            base.w100,
            main.homeBusinessList
          )}
        >
          {images.map(image => (
            <li
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyStart,
                base.w100,
                main.homeBusinessItem
              )}
            >
              <img
                src={image.url}
                alt=""
                aria-hidden="true"
                className={cx(base.mr3, main.homeBusinessIcon)}
              />
              <p
                className={cx(base.ma0, base.title2, base.ttc, theme.colorDark)}
              >
                {image.label}
              </p>
            </li>
          ))}
        </ul>
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.ma2,
            base.w100,
            main.homeBusinessCopy
          )}
        >
          <h2 className={cx(base.mb3, main.subHeading, theme.colorDark)}>
            {businessHeading}
          </h2>
          <p
            className={cx(
              base.mb4,
              base.mt2,
              main.homeBusinessCopyMain,
              main.stCopy,
              theme.colorDark
            )}
          >
            <CleanHTML html={businessCopy.childMarkdownRemark.html} />
          </p>
          <p
            className={cx(
              base.ma0,
              main.subHeading,
              main.subHeadingAlt,
              theme.colorBlue
            )}
          >
            <CleanHTML html={businessFooter} />
          </p>
        </div>
      </div>
    </section>
  );
};

export default Business;
