import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import SolutionsLilnks from '../solutionsLinks';

/**
 * Homepage Solutions component.
 * @component
 *
 * @returns {component} - <Solutions />
 */

const Solutions = () => {
  const {
    contentfulHomepage: { solutionsHeading },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        solutionsHeading
      }
    }
  `);
  return (
    <section
      id="solutions"
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.homeSolutions
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyBetween,
          base.w100,
          main.homeSolutionsInner
        )}
      >
        <h2
          className={cx(
            base.pb3,
            base.pl3,
            base.pr3,
            main.subHeading,
            main.mb60,
            theme.colorDark
          )}
        >
          {solutionsHeading}
        </h2>
        <SolutionsLilnks header={false} />
      </div>
    </section>
  );
};

export default Solutions;
