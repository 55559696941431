import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import CleanHTML from '../cleanHTML';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * Homepage Good component.
 * @component
 *
 * @returns {component} - <Good />
 */

const Good = () => {
  const {
    contentfulHomepage: {
      goodHandsHeading,
      goodHandsBody,
      goodHandsCTAName,
      goodHandsCtaLink,
      goodHandsFirstStatNumbers,
      goodHandsFirstStatCopy,
      goodHandsSecondStatNumbers,
      goodHandsSecondStatCopy,
      goodHandsThirdStatNumbers,
      goodHandsThirdStatCopy,
      goodHandsForthStatNumbers,
      goodHandsForthStatCopy,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        goodHandsHeading
        goodHandsBody {
          childMarkdownRemark {
            html
          }
        }
        goodHandsCTAName
        goodHandsCtaLink
        goodHandsFirstStatNumbers
        goodHandsFirstStatCopy
        goodHandsSecondStatNumbers
        goodHandsSecondStatCopy
        goodHandsThirdStatNumbers
        goodHandsThirdStatCopy
        goodHandsForthStatNumbers
        goodHandsForthStatCopy
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.relative,
        base.w100,
        main.homeGoodHands,
        main.px60
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyBetween,
          base.relative,
          base.w100,
          main.homeGoodHandsInner
        )}
      >
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.ma2,
            base.w100,
            main.homeGoodHandsCopy
          )}
        >
          <h2 className={cx(base.mb3, main.subHeading, theme.colorWhite)}>
            {goodHandsHeading}
          </h2>
          <p
            className={cx(
              base.mb3,
              main.homeGoodHandsCopyMain,
              main.stCopy,
              theme.colorWhite
            )}
          >
            <CleanHTML html={goodHandsBody.childMarkdownRemark.html} />
          </p>
          <Link
            to={goodHandsCtaLink}
            className={cx(
              main.button,
              theme.colorBlue,
              theme.dpBtnWhite,
              theme.title3
            )}
          >
            {goodHandsCTAName}
          </Link>
        </div>
        <ul
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsEnd,
            base.justifyBetween,
            base.ma2,
            base.pa0,
            main.homeGoodHandsNumbers
          )}
        >
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsEnd,
              base.justifyStart,
              base.w100
            )}
          >
            <p className={cx(base.ma0, theme.colorWhite, theme.title1)}>
              <span aria-hidden="true" className={theme.colorViolet}>
                ~{' '}
              </span>
              {goodHandsFirstStatNumbers}
            </p>
            <p className={cx(base.ma0, theme.colorWhite, theme.title3)}>
              {goodHandsFirstStatCopy}
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsEnd,
              base.justifyStart,
              base.w100
            )}
          >
            <p className={cx(base.ma0, theme.colorWhite, theme.title1)}>
              <span aria-hidden="true" className={theme.colorViolet}>
                ~{' '}
              </span>
              {goodHandsSecondStatNumbers}
            </p>
            <p className={cx(base.ma0, theme.colorWhite, theme.title3)}>
              {goodHandsSecondStatCopy}
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsEnd,
              base.justifyStart,
              base.w100
            )}
          >
            <p className={cx(base.ma0, theme.colorWhite, theme.title1)}>
              <span aria-hidden="true" className={theme.colorViolet}>
                ~{' '}
              </span>
              {goodHandsThirdStatNumbers}
            </p>
            <p className={cx(base.ma0, theme.colorWhite, theme.title3)}>
              {goodHandsThirdStatCopy}
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsEnd,
              base.justifyStart,
              base.w100
            )}
          >
            <p className={cx(base.ma0, theme.colorWhite, theme.title1)}>
              <span aria-hidden="true" className={theme.colorViolet}>
                ~{' '}
              </span>
              {goodHandsForthStatNumbers}
            </p>
            <p className={cx(base.ma0, theme.colorWhite, theme.title3)}>
              {goodHandsForthStatCopy}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Good;
