import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';
import WistiaVideo from '../wistiaVideo';

const CTA = () => {
  const {
    contentfulHomepage: { wistiaHeading, wistiaVideoId },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        wistiaHeading
        wistiaVideoId
      }
    }
  `);
  return (
    <section
      id="video-cta"
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.ctaWrap,
        theme.shapeBgGrey
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyCenter,
          base.mb2,
          base.ml1,
          base.mr1,
          base.mt2,
          base.w100,
          main.ctaContainer
        )}
      >
        {process.env.NODE_ENV === 'production' && (
          <WistiaVideo videoID={wistiaVideoId} />
        )}
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.pa3,
            base.w100,
            main.ctaCopyContainer,
            theme.colorDark
          )}
        >
          <p
            className={cx(
              base.mb3,
              base.mt0,
              base.pb2,
              base.pt2,
              base.w100,
              main.subHeading
            )}
          >
            {wistiaHeading}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CTA;
