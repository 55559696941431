import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import CleanHTML from '../cleanHTML';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

const brandImg = {
  objectFit: 'contain',
  maxWidth: '175px',
};

const brandImgWrap = {
  maxWidth: '170px',
};

/**
 * Homepage Quote component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Quote />
 */

const Quote = () => {
  const {
    contentfulHomepage: { quoteBody, quoteAuthor, brand },
    quotes,
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        quoteBody {
          childMarkdownRemark {
            html
          }
        }
        quoteAuthor
        brand: quoteLogo {
          fluid(maxWidth: 250, quality: 95, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      quotes: contentfulAsset(title: { eq: "quotes" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.homeQuote
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyBetween,
          base.relative,
          base.w100,
          main.homeQuoteInner
        )}
      >
        <img
          src={quotes.file.url}
          alt=""
          aria-hidden="true"
          className={cx(base.absolute, base.left0, main.homeQuoteIcon)}
        />
        <p className={cx(main.mb60, main.homeQuoteCopy)}>
          <CleanHTML html={quoteBody.childMarkdownRemark.html} />
        </p>
        <p
          className={cx(
            base.b,
            base.flex,
            base.itemsCenter,
            base.ma0,
            base.w100,
            theme.colorDark,
            theme.title3
          )}
        >
          <span className={cx(base.normal, base.ma3, theme.colorTextGrey)}>
            - {quoteAuthor}
          </span>
          <Img
            fluid={brand.fluid}
            alt="SelectHealth"
            style={brandImgWrap}
            imgStyle={brandImg}
            className={base.w100}
          />
        </p>
      </div>
    </section>
  );
};

export default Quote;
