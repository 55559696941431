import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import CleanHTML from '../cleanHTML';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

/**
 * Homepage Engage component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Engage />
 */

const Engage = () => {
  const {
    contentfulHomepage: {
      engageHeading,
      engageFirstItem,
      engageSecondItem,
      engageThirdItem,
      plans,
      strategy,
      turnKey,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        engageHeading
        engageFirstItem {
          childMarkdownRemark {
            html
          }
        }
        turnKey: engageFirstItemImage {
          file {
            url
          }
        }
        engageSecondItem {
          childMarkdownRemark {
            html
          }
        }
        strategy: engageSecondItemImage {
          file {
            url
          }
        }
        engageThirdItem {
          childMarkdownRemark {
            html
          }
        }
        plans: engageThirdItemImage {
          file {
            url
          }
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.data,
        main.homeEngage
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.homeDataItem,
          main.pt90
        )}
      >
        <h2 className={cx(base.tc, main.subHeading)}>
          <CleanHTML html={engageHeading} />
        </h2>
        <ul
          className={cx(
            base.flex,
            base.itemsStart,
            base.justifyCenter,
            base.w100,
            main.homeEngageCardList,
            main.ln
          )}
        >
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.pa4,
              base.w100,
              main.homeEngageCard
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.mb3,
                main.homeEngageIconWrap
              )}
            >
              <img
                src={turnKey.file.url}
                alt=""
                aria-hidden="true"
                className={main.homeEngageIcon}
              />
            </div>
            <CleanHTML html={engageFirstItem.childMarkdownRemark.html} />
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.pa4,
              base.w100,
              main.homeEngageCard
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.mb3,
                main.homeEngageIconWrap
              )}
            >
              <img
                src={strategy.file.url}
                alt=""
                aria-hidden="true"
                className={main.homeEngageIcon}
              />
            </div>
            <CleanHTML html={engageSecondItem.childMarkdownRemark.html} />
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.pa4,
              base.w100,
              main.homeEngageCard
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.mb3,
                main.homeEngageIconWrap
              )}
            >
              <img
                src={plans.file.url}
                alt=""
                aria-hidden="true"
                className={main.homeEngageIcon}
              />
            </div>
            <CleanHTML html={engageThirdItem.childMarkdownRemark.html} />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Engage;
