import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

const imgStyle = {
  width: '150px',
};

const wrapStyle = {
  ...imgStyle,
  margin: '0 auto',
};

const useSize = target => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, entry => setSize(entry.contentRect));

  return size;
};

const shuffleArrs = ([...arr]) => {
  let match = arr.length;

  while (match) {
    // eslint-disable-next-line no-plusplus
    const index = Math.floor(Math.random() * match--);

    // eslint-disable-next-line no-param-reassign
    [arr[match], arr[index]] = [arr[index], arr[match]];
  }

  return arr;
};

/**
 * Companies module component
 * @component
 *
 * @returns {component} - <Companies />
 */

const Companies = () => {
  const target = useRef(null);
  const size = useSize(target);
  const [slideLength, setSlideLength] = useState(4);
  const {
    contentfulHomepage: { companiesHeading, companiesCarousel },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        companiesHeading
        companiesCarousel {
          file {
            contentType
            url
          }
          fluid(maxHeight: 120, quality: 85, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);

  useEffect(() => {
    switch (true) {
      case size && size.width < 545:
        setSlideLength(1);
        break;
      case size && size.width < 695:
        setSlideLength(2);
        break;
      case size && size.width < 850:
        setSlideLength(3);
        break;
      case size && size.width < 985:
        setSlideLength(4);
        break;
      default:
        setSlideLength(4);
        break;
    }
  }, [size]);

  const images = companiesCarousel.map(item => ({
    img: item.fluid || item.file.url,
    alt: '',
    type: item.file.contentType.replace(/image\/([a-z]+).*/g, '$1'),
  }));
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideLength,
    slidesToScroll: slideLength,
    touchMove: true,
  };

  return (
    <section ref={target} id={main.companies} className={base.w100}>
      <h2>{companiesHeading}</h2>
      <Slider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...settings}
        className={cx(base.w100, main.companiesList)}
      >
        {images &&
          shuffleArrs(images).map(item =>
            item.type === 'svg' ? (
              <div>
                <img
                  src={item.img}
                  alt={item.alt}
                  style={imgStyle}
                  ariaHidden="true"
                />
              </div>
            ) : (
              <div>
                <Img
                  fluid={item.img}
                  alt={item.alt}
                  style={wrapStyle}
                  imgStyle={imgStyle}
                  ariaHidden="true"
                />
              </div>
            )
          )}
      </Slider>
    </section>
  );
};

export default Companies;
