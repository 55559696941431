import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import CleanHTML from '../cleanHTML';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

const sizing = {
  height: '70vh',
  width: '100vw',
};

/**
 * Homepage Hero component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Hero />
 */

const Hero = () => {
  const {
    contentfulHomepage: {
      heroBackground,
      heroHeading,
      heroCopy,
      heroFirstCtaName,
      heroFirstCtaLink,
      heroSecondCtaName,
      heroSecondCtaLink,
      heroHiringCtaName,
      heroHiringCtaLink,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        heroBackground {
          fixed(height: 861, width: 2066, quality: 85, resizingBehavior: FILL) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        heroHeading
        heroCopy {
          childMarkdownRemark {
            html
          }
        }
        heroFirstCtaName
        heroFirstCtaLink
        heroSecondCtaName
        heroSecondCtaLink
        heroHiringCtaName
        heroHiringCtaLink
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.hero
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.relative,
          base.w100,
          main.heroInnerReverse
        )}
      >
        <Img
          fixed={heroBackground.fixed}
          alt=""
          aria-hidden="true"
          loading="eager"
          objectPosition="35% bottom"
          style={sizing}
          imgStyle={sizing}
          className={main.homeHeroImg}
        />
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.h100,
            base.pa5,
            base.ml6,
            base.pt6,
            base.w100,
            base.z5,
            main.heroCopy,
            main.heroCopyHome,
            main.heroCopyReverse
          )}
        >
          <h1 className={cx(main.heading, main.mb30, theme.colorDark)}>
            {heroHeading}
          </h1>
          <p className={cx(base.fw5, main.mb30, theme.colorDark)}>
            <CleanHTML html={heroCopy.childMarkdownRemark.html} />
          </p>
          <ul
            className={cx(
              base.flex,
              base.flexWrap,
              base.itemsCenter,
              base.justifyStart,
              base.mt0,
              base.w100,
              main.homeButtons,
              main.ln
            )}
          >
            <li className={cx(base.ma0, base.pa0)}>
              <a
                href={heroFirstCtaLink}
                className={cx(
                  base.mr3,
                  base.ml0,
                  main.button,
                  main.homeButtonSee,
                  main.homeHeroButton,
                  theme.colorDark,
                  theme.dpBtnWhite,
                  theme.title3
                )}
              >
                {heroFirstCtaName}
              </a>
            </li>
            <li className={cx(base.ma0, base.pa0)}>
              <Link
                to={heroSecondCtaLink}
                className={cx(
                  base.ma2,
                  base.ml0,
                  main.button,
                  main.homeButtonGet,
                  main.homeHeroButton,
                  theme.colorWhite,
                  theme.dpBtnBlue,
                  theme.title3
                )}
              >
                {heroSecondCtaName}
              </Link>
            </li>
          </ul>
          <Link
            to={heroHiringCtaLink}
            className={cx(
              main.button,
              main.homeHeroHire,
              theme.colorWhite,
              theme.dpBtnViolet,
              theme.title3
            )}
          >
            {heroHiringCtaName}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
